<template>
    <div>
        <div class="title color-white box-shadow" :style="titleStyle" @click="$nav.push('/notice/details/28')">
            <div class="content-box d-flex-center">
                <div class="ml-30 mr-auto">
                    <div class="mb-10" style="font-size: 38px">{{ $t('fund.banner2.title1') }}</div>
                    <div class="mb-10 font-24">{{ $t('fund.banner2.title2') }}</div>
                </div>
            </div>
        </div>
        <el-card class="content-box d-flex-center bg-white my-20">
            <el-button class="bg-t mx-10" type="primary" plain @click="fatchData()">{{ $t('fund.newest') }}</el-button>
            <el-button class="bg-t mx-10" type="primary" plain @click="$nav.push('/fund/log')">{{ $t('fund.my_participation') }}</el-button>
            <el-button class="bg-t mx-10" type="primary" plain @click="$nav.push('/notice/details/28')">{{ $t('fund.incentive_mechanism') }}</el-button>
        </el-card>
        <div class="content-box pb-50">
            <el-row v-loading="loading" :gutter="20">
                <el-col :span="8" v-for="item in coinList.data" :key="item.id">
                    <el-card class="mb-20">
                        <div slot="header" class="clearfix">
                            <span class="font-18">USDT {{ $t('fund.locked_staking') }}</span>
                            <el-button v-if="opened" style="float: right; padding: 3px 0 3px 8px" type="text">{{ $t('fund.ongoing') }} (00:{{ formatMinutes }}:{{ formatSeconds }})</el-button>
                            <el-button v-else style="float: right; padding: 3px 0 3px 8px" type="text" disabled>{{ $t('fund.countdown_starts') }} (00:{{ formatMinutes }}:{{ formatSeconds }})</el-button>
                        </div>
                        <div class="d-flex-center">
                            <div class="mr-auto">
                                <div class="mt-10">
                                    <span class="font-12">USDT {{ $t('fund.profit') }}</span><span class="color-s ml-10 font-18">{{item.usdt_return_rate}}%</span>
                                </div>
                                <div>
                                    <span class="font-12">{{ item.coin }} {{ $t('fund.profit') }}</span><span class="color-s ml-10 font-18">{{item.return_rate}}%</span>
                                </div>
                            </div>
                            <div class="font-24">{{ item.cycle }} {{ $t('fund.days') }}</div>
                        </div>
                        <div class="d-flex-center mt-10">
                            <div class="mr-auto">
                                <div class="font-12">{{ $t('fund.user_limit') }}</div>
                                <div class="font-18 mt-5">{{item.user_limit}}</div>
                            </div>
                            <div>
                                <div class="font-12">{{ $t('fund.applicants_user') }}</div>
                                <div class="font-18 mt-5 text-right">{{item.user_current}}</div>
                            </div>
                        </div>
                        <div class="d-flex-center mt-20">
                            <div class="mr-auto">
                                <div class="font-12">{{ $t('fund.buy_range') }} (USDT)</div>
                                <div class="font-18 mt-5">{{ item.buy_min }} ~ {{ item.buy_max }}</div>
                            </div>
                            <el-button type="primary" plain class="bg-t" @click="onBuy(item.id)">{{ $t('fund.read_more') }}</el-button>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <buy-dialog :buying-id="buyingId" @buy-success="onBuySuccess" />
    </div>
</template>

<script>
import buyDialog from './buy'
import iocBanner from '@/assets/index/2.jpg'
export default {
    components: { buyDialog },
    data() {
        return {
            titleStyle: {
                backgroundImage: 'url(\''+iocBanner+'\')'
            },
            coinList: {
                data: [],
                open: 1
            },
            loading: false,
            autoLoading: false,
            buyShow: false,
            buyingId: 0,
            timer: null,
            timer2: null,
            time: new Date(),
            opened: false,
            minutes: 0,
            seconds: 0
        }
    },
    computed: {
        formatMinutes: function (){
            if(this.minutes < 10){
                return '0'+''+this.minutes
            }else{
                return this.minutes
            }
        },
        formatSeconds: function (){
            if(this.seconds < 10){
                return '0'+''+this.seconds
            }else{
                return this.seconds
            }
        }
    },
    created: function () {
        this.fatchTime()
        this.fatchData()
        this.timer = window.setInterval(() => {
            if (document.hidden || this.buyShow) return
            this.fatchData(false)
        }, 4500)
    },
    beforeDestroy: function () {
        window.clearInterval(this.timer)
        window.clearInterval(this.timer2)
    },
    methods: {
        fatchTime: async function (){
            const { data } = await this.$request.get('coin_fund/get_time')
            this.time.setTime(data * 1000)
            this.timer2 = window.setInterval(() => {
                this.time.setTime(this.time.getTime() + 1000)
                const minutes = this.time.getMinutes()
                const seconds = this.time.getSeconds();
                if(minutes >= 55){
                    this.opened = false
                    this.minutes = 4 - (minutes - 55)
                    if(seconds == 0){
                        this.minutes++
                    }
                }else{
                    this.opened = true
                    this.minutes = 54 - minutes
                }
                if(seconds == 0){
                    this.seconds = 0
                }else{
                    this.seconds = 60 - seconds
                }
                
            }, 1000)
        },
        fatchData: async function (createdLoad = true){
            try {
                let res;
                if(createdLoad){
                    this.loading = true
                    res = await this.$request.post('coin_fund')
                }else{
                    if(this.autoLoading) return
                    this.autoLoading = true
                    res = await this.$requestNotState.post('coin_fund')
                }
                this.coinList = res.data
                this.loading = false
                this.autoLoading = false
            } catch (error) {
                this.loading = false
                this.autoLoading = false
            }
        },
        onBuy(id) {
            this.buyingId = id
            this.buyShow = true
        },
        onBuySuccess: function (){
            this.buyShow = false
        }
    }
}
</script>

<style lang="scss" scoped>
.title{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    height: 280px;
    cursor: pointer;
    .content-box{
        height: 100%;
    }
}
</style>